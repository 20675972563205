/* eslint-disable no-unused-vars */
import {
  Uil500px,
  UilAirplay,
  UilArrowGrowth,
  UilAt,
  UilBagAlt,
  UilBookAlt,
  UilBookOpen,
  UilBookReader,
  UilCalendarAlt,
  UilChartBar,
  UilChat,
  UilCheckSquare,
  UilCircle,
  UilClipboardAlt,
  UilClock,
  UilCompactDisc,
  UilCreateDashboard,
  UilDatabase,
  UilDocumentLayoutLeft,
  UilEdit,
  UilEnvelope,
  UilExchange,
  UilExclamationOctagon,
  // UilExpandArrowsAlt,
  UilFile,
  UilFileShieldAlt,
  UilHeadphones,
  UilIcons,
  UilImages,
  UilLayerGroup,
  UilMap,
  UilPresentation,
  UilQuestionCircle,
  UilSearch,
  UilServer,
  UilSetting,
  UilShoppingCart,
  UilSquareFull,
  UilTable,
  UilUsdCircle,
  UilUsersAlt,
  UilWindowSection,
  UilShieldCheck,
  UilFilesLandscapes,
} from '@iconscout/react-unicons';
import { Menu, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../controller/redux/themeLayout/actionCreator';
import { useCurrentUser } from '../model/queryCalls/queryCalls';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();
  const { data, isLoading } = useCurrentUser();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector('html');
    if (topMode) {
      html.classList.add('hexadash-topmenu');
    } else {
      html.classList.remove('hexadash-topmenu');
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
    } else {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add('dark');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark');
  };

  let layoutManagementSection = null;
  let dashboard = null;
  let rolesManagementSection = null;
  let postersManagementSection = null;
  let hocalwareManagementSection = null;
  let trendingTagsSection = null;
  let podcastsSection = null;
  // let cmsSection = null;
  let appSettings = null;
  let appCustomers = null;
  let reportsSection = null; // New reports section

  layoutManagementSection = getItem(t('layouts'), 'layout', !topMenu && <UilWindowSection />, [
    getItem(
      <NavLink
        onClick={() => {
          toggleCollapsed();
          darkmodeDiactivated();
          changeLayout('lightMode');
        }}
        to="#"
      >
        {t('light')} {t('mode')}
      </NavLink>,
      'light',
      null,
    ),
    getItem(
      <NavLink
        onClick={() => {
          toggleCollapsed();
          darkmodeActivated();
          changeLayout('darkMode');
        }}
        to="#"
      >
        {t('dark')} {t('mode')}
      </NavLink>,
      'dark',
      null,
    ),
    getItem(
      <NavLink
        onClick={() => {
          toggleCollapsed();
          changeNavbar(true);
        }}
        to="#"
      >
        {t('top')} {t('menu')}
      </NavLink>,
      'topMenu',
      null,
    ),
    getItem(
      <NavLink
        onClick={() => {
          toggleCollapsed();
          changeNavbar(false);
        }}
        to="#"
      >
        {t('side')} {t('menu')}
      </NavLink>,
      'sideMenu',
      null,
    ),
    getItem(
      <NavLink
        onClick={() => {
          toggleCollapsed();
          changeLayoutDirection(true);
        }}
        to="#"
      >
        RTL
      </NavLink>,
      'rtl',
      null,
    ),
    getItem(
      <NavLink
        onClick={() => {
          toggleCollapsed();
          changeLayoutDirection(false);
        }}
        to="#"
      >
        LTR
      </NavLink>,
      'ltr',
      null,
    ),
  ]);

  dashboard = getItem(
    <NavLink onClick={toggleCollapsed} to={`${path}`}>
      {t('dashboard')}
    </NavLink>,
    'dashboard',
    !topMenu && (
      <NavLink className="menuItem-iocn" to={`${path}`}>
        <UilCreateDashboard />
      </NavLink>
    ),
  );
  const rolesList = ['roleRead', 'roleReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/roles`}>
          {t('All Roles')}
        </NavLink>,
        'All Roles',
        null,
      )
    : null;
  const addRole = data?.permissions.includes('roleReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/roles/addNewRole`}>
          {t('Add a Role')}
        </NavLink>,
        'Add a Role',
        null,
      )
    : null;

  const usersList = ['usersRead', 'usersReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
          {t('All Users')}
        </NavLink>,
        'All Users',
        null,
      )
    : null;

  const addUser = data?.permissions.includes('usersReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/addNewUser`}>
          {t('Add New User')}
        </NavLink>,
        'Add New User',
        null,
      )
    : null;

  rolesManagementSection = ['roleRead', 'roleReadWrite', 'usersRead', 'usersReadWrite'].some((permission) =>
    data?.permissions.includes(permission),
  )
    ? getItem(t('Roles & Users'), 'Roles & Users', !topMenu && <UilShieldCheck />, [
        rolesList,
        addRole,
        usersList,
        addUser,
      ])
    : null;

  const postersList = ['postersRead', 'postersReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/posters`}>
          {t('All News Snap')}
        </NavLink>,
        'All News Snap',
        null,
      )
    : null;

  const addPosters = data?.permissions.includes('postersReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/posters/addPoster`}>
          {t('Add News Snap')}
        </NavLink>,
        'Add News Snap',
        null,
      )
    : null;

  postersManagementSection = ['postersRead', 'postersReadWrite'].some((permission) =>
    data?.permissions.includes(permission),
  )
    ? getItem(t('News Snap'), 'News Snap', !topMenu && <UilImages />, [postersList, addPosters])
    : null;

  const HocalwareList = ['cmsRead', 'cmsReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/categories`}>
          {t('Categories')}
        </NavLink>,
        'Categories',
        null,
      )
    : null;
  const tagsSettings = data?.permissions.includes('cmsReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/tags`}>
          {t('Tags')}
        </NavLink>,
        'Tags',
        null,
      )
    : null;

  const news = data?.permissions.includes('cmsReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/news`}>
          {t('News')}
        </NavLink>,
        'News',
        null,
      )
    : null;

  const showsSettings = data?.permissions.includes('cmsReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/show`}>
          {t('Shows')}
        </NavLink>,
        'Shows',
        null,
      )
    : null;

  hocalwareManagementSection = ['cmsRead', 'cmsReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(t('Hocalware CMS'), 'Hocalware CMS', !topMenu && <UilImages />, [
        HocalwareList,
        tagsSettings,
        news,
        showsSettings,
      ])
    : null;

  trendingTagsSection = ['trendingTagsRead', 'trendingTagsReadWrite'].some((permission) =>
    data?.permissions.includes(permission),
  )
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/trendingTags/addTrendingTag`}>
          {t('Trending Tags')}
        </NavLink>,
        'Trending Tags',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/trendingTags/addTrendingTag`}>
            <UilArrowGrowth />
          </NavLink>
        ),
      )
    : null;

  const shows = ['podcastsRead', 'podcastsReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/shows`}>
          {t('All Podcast Shows')}
        </NavLink>,
        'All Podcast Shows',
        null,
      )
    : null;

  const addShow = data?.permissions.includes('podcastsReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/shows/addShows`}>
          {t('Add Podcast Show')}
        </NavLink>,
        'Add Podcast Show',
        null,
      )
    : null;

  const episodes = ['podcastsRead', 'podcastsReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/podcasts`}>
          {t('All Audio')}
        </NavLink>,
        'All Audio',
        null,
      )
    : null;

  const addEpisode = data?.permissions.includes('podcastsReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/podcasts/addPodcast`}>
          {t('Add Audio')}
        </NavLink>,
        'Add Audio',
        null,
      )
    : null;

  podcastsSection = ['podcastsRead', 'podcastsReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(t('Audio'), 'Audio', !topMenu && <UilHeadphones />, [shows, addShow, episodes, addEpisode])
    : null;
  // const cmsList = ['cmsRead', 'cmsReadWrite'].some((permission) => data?.permissions.includes(permission))
  //   ? getItem(
  //       <NavLink onClick={toggleCollapsed} to={`${path}/cms`}>
  //         {t('All CMS')}
  //       </NavLink>,
  //       'All CMS',
  //       null,
  //     )
  //   : null;
  // const addCms = data?.permissions.includes('cmsReadWrite')
  //   ? getItem(
  //       <NavLink onClick={toggleCollapsed} to={`${path}/cms/addCms`}>
  //         {t('Add Page')}
  //       </NavLink>,
  //       'Add Page',
  //       null,
  //     )
  //   : null;
  // cmsSection = ['cmsRead', 'cmsReadWrite'].some((permission) => data?.permissions.includes(permission))
  //   ? getItem(t('CMS Management'), 'CMS Management', !topMenu && <UilEdit />, [cmsList, addCms])
  //   : null;

  const settings = ['appSettingsRead', 'appSettingsReadWrite'].some((permission) =>
    data?.permissions.includes(permission),
  )
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings`}>
          {t('Settings')}
        </NavLink>,
        'Settings',
        null,
      )
    : null;
  const eventSettings = ['appEventSettingsRead', 'appEventSettingsReadWrite'].some((permission) =>
    data?.permissions.includes(permission),
  )
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/events`}>
          {t('Events')}
        </NavLink>,
        'Events',
        null,
      )
    : null;
  // const appShowsSettings = ['appShowSettingsRead', 'appShowSettingsReadWrite'].some((permission) =>
  //   data?.permissions.includes(permission),
  // )
  //   ? getItem(
  //       <NavLink onClick={toggleCollapsed} to={`${path}/settings/appShows`}>
  //         {t('App Shows')}
  //       </NavLink>,
  //       'App Shows',
  //       null,
  //     )
  //   : null;

  const cmsList = ['cmsRead', 'cmsReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms`}>
          {t('App CMS')}
        </NavLink>,
        'App CMS',
        null,
      )
    : null;
  const addCms = data?.permissions.includes('cmsReadWrite')
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/addCms`}>
          {t('Add CMS')}
        </NavLink>,
        'Add CMS',
        null,
      )
    : null;
  const appMoreCategoriesSettings = ['appMoreCategoriesSettingsRead', 'appMoreCategoriesSettingsReadWrite'].some(
    (permission) => data?.permissions.includes(permission),
  )
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/appMoreCategories`}>
          {t('App More Categories')}
        </NavLink>,
        'App More Categories',
        null,
      )
    : null;
  const appPersonalizedCategoriesSettings = [
    'appPersonalizedCategoriesSettingsRead',
    'appPersonalizedCategoriesSettingsReadWrite',
  ].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/appPersonalizedCategories`}>
          {t('App Personalized Categories')}
        </NavLink>,
        'App Personalized Categories',
        null,
      )
    : null;
  const pushNotificationsForNewsSettings = [
    'appPushNotificationsForNewsSettingsRead',
    'appPushNotificationsForNewsSettingsReadWrite',
  ].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/pushNotificationsForNews`}>
          {t('Push Notifications')}
        </NavLink>,
        'Push Notifications For News',
        null,
      )
    : null;

  appSettings = [
    'appSettingsRead',
    'appSettingsReadWrite',
    'appEventSettingsRead',
    'appEventSettingsReadWrite',
    'appShowSettingsRead',
    'appShowSettingsReadWrite',
    'appMoreCategoriesSettingsRead',
    'appMoreCategoriesSettingsReadWrite',
    'appPersonalizedCategoriesSettingsRead',
    'appPersonalizedCategoriesSettingsReadWrite',
    'appPushNotificationsForNewsSettingsRead',
    'appPushNotificationsForNewsSettingsReadWrite',
    'cmsReadWrite',
    'cmsReadWrite',
  ].some((permission) => data?.permissions.includes(permission))
    ? getItem(t('App Settings'), 'App Settings', !topMenu && <UilSetting />, [
        settings,
        eventSettings,
        // appShowsSettings,
        appMoreCategoriesSettings,
        appPersonalizedCategoriesSettings,
        pushNotificationsForNewsSettings,
        cmsList,
        addCms,
      ])
    : null;

  appCustomers = ['customersRead', 'customersReadWrite'].some((permission) => data?.permissions.includes(permission))
    ? getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/appCustomers`}>
          {t('Customers')}
        </NavLink>,
        'Customers',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/appCustomers`}>
            <UilUsersAlt />
          </NavLink>
        ),
      )
    : null;

  reportsSection = getItem(
    <NavLink onClick={toggleCollapsed} to={`${path}/reports`}>
      {t('Reports')}
    </NavLink>,
    'reports',
    !topMenu && <UilChartBar />,
  );

  const menuItems = [
    dashboard,
    rolesManagementSection,
    postersManagementSection,
    trendingTagsSection,
    podcastsSection,
    appCustomers,
    // cmsSection,
    appSettings,
    hocalwareManagementSection,
    reportsSection, // Add reports section here
  ];

  return isLoading ? (
    <div className="flex justify-center align-items-center">
      <Spin />
    </div>
  ) : (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? 'dashboard'
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={menuItems}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
