import { useQuery } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../../controller/context/userContext';
import {
  app,
  getOneRole,
  getRoles,
  getRegisterRoles,
  getCurrentUser,
  getSearchPosters,
  getPosters,
  getOnePoster,
  getTags,
  getOneTrendingTag,
  getTrendingTags,
  getTrendingTagIdExist,
  getTrendingOrderNumberExist,
  getPodcasts,
  getSearchPodcasts,
  getOnePodcast,
  getShows,
  getOneShow,
  getShowsSelect,
  getCms,
  getOneCms,
  getOneMaintenance,
  getDefaultTags,
  getOneAds,
  getAppCustomers,
  getEvents,
  getOneEvent,
  getCategories,
  getAppShows,
  getOneLiveTv,
  getAppMoreCategories,
  getAppPersonalizedCategories,
  getNews,
  getNewsPosters,
  getUserRegisterRoles,
  getOneUser,
  getUsers,
  getAllTags,
  getAllCategories,
} from '../../controller/services/dbServices';

export const useCurrentUser = () => {
  const { user } = useContext(UserContext);
  return useQuery(['currentUser', user, user?.customData, { id: user?.customData?.entityId || null }], () =>
    getCurrentUser({ id: user?.customData?.entityId || null }),
  );
};

export const useRoles = () => {
  const { user } = useContext(UserContext);
  return useQuery(['rolesData', user], () => getRoles());
};

export const useOneRole = ({ id }) => {
  const { user } = useContext(UserContext);
  return useQuery(['oneRole', user, { id }], () => getOneRole({ id }));
};

export const useRegisterRoles = () => {
  return useQuery(['registerRoles', app.currentUser], () => getRegisterRoles());
};

export const useSearchPosters = ({ currentPage, pageSize, search }) => {
  const { user } = useContext(UserContext);
  return useQuery(['searchPostersData', user, currentPage, pageSize, search], () =>
    getSearchPosters({ currentPage, pageSize, search }),
  );
};

export const usePosters = ({ currentPage, pageSize }) => {
  const { user } = useContext(UserContext);
  return useQuery(['postersData', user, currentPage, pageSize], () => getPosters({ currentPage, pageSize }));
};

export const useOnePoster = ({ id }) => {
  const { user } = useContext(UserContext);
  return useQuery(['onePoster', user, { id }], () => getOnePoster({ id }));
};

export const useTags = ({ search, entity }) => {
  const { user } = useContext(UserContext);
  return useQuery(['tagsData', user], () => getTags({ search, entity }), { enabled: search.length > 0 });
};

export const useDefaultTags = ({ selectedTagIds }) => {
  const { user } = useContext(UserContext);

  return useQuery(['tagsDefaultData', user, selectedTagIds], () => getDefaultTags(selectedTagIds), {
    // Only run the query if selectedTagIds is not empty
    enabled: selectedTagIds.length > 0,
  });
};

export const useOneTrendingTag = ({ id }) => {
  const { user } = useContext(UserContext);
  return useQuery(['oneTrendingTag', user, { id }], () => getOneTrendingTag({ id }));
};

export const useTrendingTags = ({ entity }) => {
  const { user } = useContext(UserContext);
  return useQuery(['trendingTagsData', user, { entity }], () => getTrendingTags({ entity }));
};

export const useTrendingTagIdExist = ({ tagId, isEdit, isEditTagId }) => {
  const { user } = useContext(UserContext);
  return useQuery(['trendingTagIdExist', user, tagId, isEdit, isEditTagId], () =>
    getTrendingTagIdExist({ tagId, isEdit, isEditTagId }),
  );
};
export const useTrendingOrderNumberExist = ({ orderNumber, isEdit, isEditOrderNumber }) => {
  const { user } = useContext(UserContext);
  return useQuery(['trendingOrderNumberExist', user, orderNumber, isEdit, isEditOrderNumber], () =>
    getTrendingOrderNumberExist({ orderNumber, isEdit, isEditOrderNumber }),
  );
};

export const usePodcasts = ({ currentPage, pageSize }) => {
  const { user } = useContext(UserContext);
  return useQuery(['podcastsData', user, currentPage, pageSize], () => getPodcasts({ currentPage, pageSize }));
};

export const useSearchPodcasts = ({ currentPage, pageSize, search }) => {
  const { user } = useContext(UserContext);
  return useQuery(['searchPodcastsData', user, currentPage, pageSize, search], () =>
    getSearchPodcasts({ currentPage, pageSize, search }),
  );
};

export const useOnePodcast = ({ id }) => {
  const { user } = useContext(UserContext);
  return useQuery(['onePodcast', user, { id }], () => getOnePodcast({ id }));
};

export const useShows = ({ currentPage, pageSize }) => {
  const { user } = useContext(UserContext);
  return useQuery(['showsData', user, currentPage, pageSize], () => getShows({ currentPage, pageSize }));
};

export const useOneShow = ({ id }) => {
  const { user } = useContext(UserContext);
  return useQuery(['oneShow', user, { id }], () => getOneShow({ id }));
};

export const useCms = () => {
  const { user } = useContext(UserContext);
  return useQuery(['cmsData', user], () => getCms());
};

export const useOneCms = ({ id }) => {
  const { user } = useContext(UserContext);
  return useQuery(['onePoster', user, { id }], () => getOneCms({ id }));
};

export const useShowsSelect = () => {
  const { user } = useContext(UserContext);
  return useQuery(['showsSelectData', user], () => getShowsSelect());
};

export const useOneMaintenance = () => {
  const { user } = useContext(UserContext);
  return useQuery(['OneMaintenance', user], () => getOneMaintenance());
};

export const useOneAds = () => {
  const { user } = useContext(UserContext);
  return useQuery(['OneAds', user], () => getOneAds());
};

export const useAppCustomers = ({ currentPage, pageSize, dateRange }) => {
  const { user } = useContext(UserContext);
  return useQuery(['appCustomersList', user, currentPage, pageSize, dateRange], () =>
    getAppCustomers({ currentPage, pageSize, dateRange }),
  );
};

export const useEvents = ({ currentPage, pageSize }) => {
  const { user } = useContext(UserContext);
  return useQuery(['eventsData', user, currentPage, pageSize], () => getEvents({ currentPage, pageSize }));
};

export const useOneEvent = ({ id }) => {
  const { user } = useContext(UserContext);
  return useQuery(['oneEvent', user, { id }], () => getOneEvent({ id }));
};

export const useCategories = () => {
  const { user } = useContext(UserContext);
  return useQuery(['categoriesData', user], () => getCategories());
};

export const useAppShows = ({ currentPage, pageSize, search }) => {
  const { user } = useContext(UserContext);
  return useQuery(['appShowsData', user, currentPage, pageSize, search], () =>
    getAppShows({ currentPage, pageSize, search }),
  );
};

export const useOneLiveTv = () => {
  const { user } = useContext(UserContext);
  return useQuery(['OneLiveTv', user], () => getOneLiveTv());
};

export const useAppMoreCategories = ({ currentPage, pageSize, search }) => {
  const { user } = useContext(UserContext);
  return useQuery(['appMoreCategoriesData', user, currentPage, pageSize, search], () =>
    getAppMoreCategories({ currentPage, pageSize, search }),
  );
};

export const useAppPersonalizedCategories = ({ currentPage, pageSize, search }) => {
  const { user } = useContext(UserContext);
  return useQuery(['appPersonalizedCategoriesData', user, currentPage, pageSize, search], () =>
    getAppPersonalizedCategories({ currentPage, pageSize, search }),
  );
};

export const useNews = ({ currentPage, pageSize, search }) => {
  const { user } = useContext(UserContext);
  return useQuery(['newsData', user, currentPage, pageSize, search], () => getNews({ currentPage, pageSize, search }));
};
export const useAllTags = ({ currentPage, pageSize }) => {
  const { user } = useContext(UserContext);
  return useQuery(['tagsData', user, currentPage, pageSize], () => getAllTags({ currentPage, pageSize }));
};
export const useAllCategories = ({ currentPage, pageSize }) => {
  const { user } = useContext(UserContext);
  return useQuery(['categoryData', user, currentPage, pageSize], () => getAllCategories({ currentPage, pageSize }));
};

export const useNewsPosters = ({ currentPage, pageSize, search, newsId }) => {
  const { user } = useContext(UserContext);
  return useQuery(['newsPostersData', user, currentPage, pageSize, search, newsId], () =>
    getNewsPosters({ currentPage, pageSize, search, newsId }),
  );
};

export const useUserRegisterRoles = () => {
  const { user } = useContext(UserContext);
  return useQuery(['userRegisterRoles', user], () => getUserRegisterRoles());
};

export const useOneUser = ({ id }) => {
  const { user } = useContext(UserContext);
  return useQuery(['oneUser', user, { id }], () => getOneUser({ id }));
};

export const useUsers = () => {
  const { user } = useContext(UserContext);
  return useQuery(['usersData', user], () => getUsers());
};
